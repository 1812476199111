import { useTeamStore } from "~/stores/team";

export default defineNuxtRouteMiddleware((to, from) => {
  // const router = useRouter();
  const route = useRoute();
  const teamStore = useTeamStore();
  // if (!teamStore.activeTeam.tenantId && route.path !== "/") {
  //   return navigateTo("/");
  // }
  console.log(route.path, to, from);
  console.log(teamStore.activeTeam.tenantId);
});
